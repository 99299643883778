const SVG_THUMB_UP='<svg fill="#ffffff" width="20px" height="20px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g stroke-width="0"/><g stroke-linecap="round" stroke-linejoin="round"/><g><path d="M456,192,300,180l23-89.4C329,64,322.22,48.73,300.53,42l-34.69-9.85a4,4,0,0,0-4.4,1.72l-129,202.34a8,8,0,0,1-6.81,3.81H16V448H133.61a48,48,0,0,1,15.18,2.46l76.3,25.43a80,80,0,0,0,25.3,4.11H428.32c19,0,31.5-13.52,35.23-32.16L496,305.58V232C496,209.94,478,194,456,192Z"/></g></svg>';export const FeedbackExtension={name:"Feedback",type:"response",match:({trace})=>{var _trace_payload;return trace.type==="ext_feedback"||((_trace_payload=trace.payload)===null||_trace_payload===void 0?void 0:_trace_payload.name)==="ext_feedback"},render:({trace,element})=>{removePreviousFeedbackElements();const feedbackContainer=document.createElement("div");feedbackContainer.innerHTML=`
      <style>
        .vfrc-feedback {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .vfrc-feedback--description {
          font-size: 0.8em;
          color: grey;
          pointer-events: none;
        }

        .vfrc-feedback--buttons {
          display: flex;
        }

        .vfrc-feedback--button {
          margin: 0;
          padding: 0;
          margin-left: 0px;
          border: none;
          background: none;
          opacity: 0.5;
        }

        .vfrc-feedback--button:hover {
          opacity: 0.8; /* opacity on hover */
          cursor: pointer;
        }

        .vfrc-feedback--button.selected {
          opacity: 0.8;
        }

        .vfrc-feedback--button.disabled {
          pointer-events: none;
        }

        .vfrc-feedback--button:first-child svg {
          stroke: none;
          border: none;
          margin-left: 6px;
        }

        .vfrc-feedback--button:last-child svg {
          margin-left: 6px;
          stroke: none;
          border: none;
          transform: rotate(180deg);
        }
      </style>
      <div class="vfrc-feedback">
        <div class="vfrc-feedback--description">Was it helpful?</div>
        <div class="vfrc-feedback--buttons">
          <button class="vfrc-feedback--button" data-feedback="1">${SVG_THUMB_UP}</button>
          <button class="vfrc-feedback--button" data-feedback="0">${SVG_THUMB_UP}</button>
        </div>
      </div>
    `;feedbackContainer.querySelectorAll(".vfrc-feedback--button").forEach(button=>{button.addEventListener("click",function(event){const feedback=this.getAttribute("data-feedback");window.voiceflow.chat.interact({type:"submit",payload:{feedback}});feedbackContainer.querySelectorAll(".vfrc-feedback--button").forEach(btn=>{btn.classList.add("disabled");if(btn===this){btn.classList.add("selected")}})})});element.appendChild(feedbackContainer)}};function removePreviousFeedbackElements(){const chatWidget=document.querySelector("#voiceflow-chat").shadowRoot.querySelector(".vfrc-widget:last-child .vfrc-chat--dialog");const feedbackWidget=chatWidget===null||chatWidget===void 0?void 0:chatWidget.querySelector(".vfrc-feedback");if(feedbackWidget){var _systemResponseContainer_previousSibling_classList,_systemResponseContainer_previousSibling,_systemResponseContainer_previousSibling1;const systemResponseContainer=feedbackWidget.closest(".vfrc-system-response");if(systemResponseContainer===null||systemResponseContainer===void 0?void 0:(_systemResponseContainer_previousSibling=systemResponseContainer.previousSibling)===null||_systemResponseContainer_previousSibling===void 0?void 0:(_systemResponseContainer_previousSibling_classList=_systemResponseContainer_previousSibling.classList)===null||_systemResponseContainer_previousSibling_classList===void 0?void 0:_systemResponseContainer_previousSibling_classList.contains("vfrc-system-response--controls"))(_systemResponseContainer_previousSibling1=systemResponseContainer.previousSibling)===null||_systemResponseContainer_previousSibling1===void 0?void 0:_systemResponseContainer_previousSibling1.remove();systemResponseContainer===null||systemResponseContainer===void 0?void 0:systemResponseContainer.remove()}}